.header-container{
    width: 100%;
}

.header-image{
    max-width: 2500px;
    width: 100%;
    height: auto;
    vertical-align: top;
}

.top-menu{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    background: transparent;
    position: absolute;
    width: 100%;
}

.menu-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.menu-tab{
    color: white;
    font-weight: bold;
    width: 7vw;
    align-content: center;
    padding: 11px;
    display: flex;
    justify-content: center;
}

.top-text{
    padding-left: 1vw;
    margin-top: 1vw;
}

.tab-text{
    color: #f4892a;
}

.tab-text:hover{
    color:#37bbd5;
}

.top-menu img{
    width: 6%;
    margin-right: 1vw;
    cursor: pointer;
    display: none;
}

.mobile-menu{
    display: none;
}

/* menu icon */
.menu{
    margin-right: 1em;
    margin-top: 0.4em;
    width: 35px;
}
.menu:after,
.menu:before,
.menu div {
    background-color:white;
    border-radius: 3px;
    content: '';
    display: block;
    height: 5px;
    margin: 4px 0;
    transition: all .2s ease-in-out;
}
/* Move the upper bar */
.menu:hover:before {
    transform: translateY(9px) rotate(135deg);
}
/* Move the lower bar */
.menu:hover:after {
    transform: translateY(-9px) rotate(-135deg);
}
/* Make the mid bar fade out */
.menu:hover div {
    transform: scale(0);
}

.search-query{
    width: 10vw;
    padding: 15px;
}

.search-query input{
    border-radius: 8px;
    border: 0.5px solid white;
    padding: 5px;
    width: 9vw;
}




@media screen and (max-width: 1023px) and (min-width: 421px){
    .mobile-menu{
        display: block;
        margin-right: 0.5vh;
        margin-top: 0.5vh;
    }

    .top-menu{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-start;
        background: #0d0b20;
        height: 4vh;
        position: relative;
    }

    .top-menu img{
        display: block;
    }

    .menu-container{
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: 4vh;
        position: absolute;
    }

    .desktop-menu{
        display: none;
    }

    .menu-tab{
        font-weight: bold;
        width: calc(100vw - 24px - 11px);
        align-content: center;
        padding: 11px;
        display: flex;
        justify-content: flex-start;
        background: #e1e0e0;
        padding-left: 24px;
    }

    .top-text{
        padding-left: 1vw;
        margin-top: 2vw;
    }

    .tab-text{
        color: black;
    }

    .tab-text:hover{
        color: darkgray;
    }

    .show-menu{
        display: flex;
    }

    .search-query{
        font-weight: bold;
        width: calc(100vw - 24px - 11px);
        align-content: center;
        padding: 11px;
        display: flex;
        justify-content: flex-start;
        background: #e1e0e0;
        padding-left: 24px;
    }

    .search-query input{
        border-radius: 8px;
        border: 0.5px solid white;
        padding: 5px;
        width: 30vw;
    }
}

@media screen and (max-device-width: 600px) {
    .mobile-menu{
        display: block;
        margin-right: 0.5vh;
        margin-top: 0.5vh;
    }

    /*.header-image{*/
    /*    height: 16vh;*/
    /*}*/

    .top-menu{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: flex-start;
        background: #0d0b20;
        height: 4vh;
        position: relative;
    }

    .top-menu img{
        display: block;
    }

    .menu-container{
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: 4vh;
        position: absolute;
    }

    .desktop-menu{
        display: none;
    }

    .menu-tab{
        font-weight: bold;
        width: calc(100vw - 24px - 11px);
        align-content: center;
        padding: 11px;
        display: flex;
        justify-content: flex-start;
        background: #e1e0e0;
        padding-left: 24px;
    }

    .top-text{
        padding-left: 1vw;
        margin-top: 2vw;
    }

    .tab-text{
        color: black;
    }

    .tab-text:hover{
        color: darkgray;
    }

    .show-menu{
        display: flex;
    }

    .search-query{
        font-weight: bold;
        width: calc(100vw - 24px - 11px);
        align-content: center;
        padding: 11px;
        display: flex;
        justify-content: flex-start;
        background: #e1e0e0;
        padding-left: 24px;
    }
    .search-query input{
        border-radius: 8px;
        border: 0.5px solid white;
        padding: 5px;
        width: 50vw;
    }
}